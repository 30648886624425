export default [
  {
    id: 'daily',
    label: 'Daily',
  },
  {
    id: 'everyXdays',
    label: 'Every "X" Days',
  },
  {
    id: 'specificDays',
    label: 'Specific Days',
  },
];
