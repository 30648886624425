<script>
import {
  onMounted,
  reactive,
  computed,
  inject,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useMutation } from '@urql/vue';
import FormInput from '@/components/form/FormInput.vue';
import Button from '@/components/Button.vue';
import MedicationPlanFieldset from '@/components/MedicationPlanFieldset.vue';
import TreatmentCycleFieldset from '@/components/TreatmentCycleFieldset.vue';
import GetTreatment from '@/queries/GetTreatment';
import UpdateTreatmentMutation from '@/queries/UpdateTreatment';
import DosageTypes from '@/utilities/DosageTypes';
import { useRouter } from 'vue-router';
import { processEditTreatmentInput } from '@/utilities/ProcessTreatmentInputs';

export default {
  components: {
    FormInput,
    Button,
    MedicationPlanFieldset,
    TreatmentCycleFieldset,
  },
  props: {
    id: String,
  },
  setup(props) {
    const EventBus = inject('EventBus');
    const router = useRouter();
    const model = reactive({
      id: '',
      name: '',
      shortName: '',
      length: 0,
      premedOptions: [],
      antiviralOptions: [],
      bloodClotPreventionOptions: [],
      medicationsForAllCycles: [],
      cycles: [],
      globalError: '',
    });

    const getIndexOfID = (list, id) => {
      let foundIndex = -1;
      list.forEach((item, index) => {
        if (item.id === id) {
          foundIndex = index;
        }
      });
      return foundIndex;
    };

    const addMedPlanFromDB = dbMedPlan => ({
      id: dbMedPlan.id,
      medication: dbMedPlan.medication,
      givenByNurse: dbMedPlan.givenByNurse ? 'Yes' : 'No',
      dosageType: DosageTypes[getIndexOfID(DosageTypes, dbMedPlan.dosageType)],
      daysInterval: dbMedPlan.daysInterval,
      daysRange: dbMedPlan.daysRange,
      length: dbMedPlan.length,
      specialInstructions: dbMedPlan.specialInstructions,
      deleted: false,
      valid: true,
    });

    const addCycleFromDB = (dbCycle) => {
      const newCycle = {
        id: dbCycle.id,
        name: dbCycle.name,
        medications: [],
        deleted: false,
        valid: true,
      };
      dbCycle.medicationPlans.forEach((medPlan) => {
        newCycle.medications.push(addMedPlanFromDB(medPlan));
      });
      return newCycle;
    };

    onMounted(async () => {
      model.id = parseInt(props.id, 10);
      const treatmentResult = await useQuery({
        query: GetTreatment,
        variables: { id: parseInt(props.id, 10) },
      });

      if (treatmentResult.data.value.treatment) {
        const value = treatmentResult.data.value.treatment;
        model.id = value.id;
        model.name = value.name;
        model.shortName = value.shortName;
        model.length = value.treatmentLength;

        value.preMedOptions.forEach((med) => {
          model.premedOptions.push(addMedPlanFromDB(med));
        });
        value.antiviralTreatment.forEach((med) => {
          model.antiviralOptions.push(addMedPlanFromDB(med));
        });
        value.antiviralTreatment.forEach((med) => {
          model.antiviralOptions.push(addMedPlanFromDB(med));
        });
        value.bloodClotPrevention.forEach((med) => {
          model.bloodClotPreventionOptions.push(addMedPlanFromDB(med));
        });
        value.rulesForAllCycles.forEach((med) => {
          model.medicationsForAllCycles.push(addMedPlanFromDB(med));
        });
        value.cycles.forEach((cycle) => {
          model.cycles.push(addCycleFromDB(cycle));
        });
      }
    });

    const addNewCycle = () => {
      model.cycles.push({
        id: `temp_${uuidv4()}`,
        name: '',
        medications: [],
        deleted: false,
        valid: false,
      });
    };

    const createNewMedicationPlan = () => ({
      id: `temp_${uuidv4()}`,
      medication: null,
      givenByNurse: '',
      dosageType: null,
      daysInterval: 0,
      daysRange: '',
      length: 0,
      specialInstructions: '',
      deleted: false,
      valid: false,
    });

    const errors = computed(() => {
      const errorMap = {
        fields: {
          name: '',
          shortName: '',
          length: '',
          premedOptions: [],
          antiviralOptions: [],
          bloodClotPreventionOptions: [],
          medicationsForAllCycles: [],
          cycles: [],
        },
        valid: true,
      };

      if (model.name.length <= 0) {
        errorMap.fields.name = 'This field is required.';
        errorMap.valid = false;
      }
      if (model.shortName.length <= 0) {
        errorMap.fields.shortName = 'This field is required.';
        errorMap.valid = false;
      }
      if (model.length <= 0) {
        errorMap.fields.length = 'This field is required and must be > 0.';
        errorMap.valid = false;
      }
      model.premedOptions.filter(item => !item.deleted).forEach((med) => {
        if (!med.valid) {
          errorMap.valid = false;
        }
      });
      model.antiviralOptions.filter(item => !item.deleted).forEach((med) => {
        if (!med.valid) {
          errorMap.valid = false;
        }
      });
      model.bloodClotPreventionOptions.filter(item => !item.deleted).forEach((med) => {
        if (!med.valid) {
          errorMap.valid = false;
        }
      });
      model.medicationsForAllCycles.filter(item => !item.deleted).forEach((med) => {
        if (!med.valid) {
          errorMap.valid = false;
        }
      });
      model.cycles.filter(item => !item.deleted).forEach((cycle) => {
        if (!cycle.valid) {
          errorMap.valid = false;
        }
      });

      return errorMap;
    });

    const { executeMutation: updateTreatment } = useMutation(UpdateTreatmentMutation);

    const saveTreatment = () => {
      EventBus.emit('show-errors');

      if (!errors.value.valid) {
        EventBus.emit('show-errors');
        return;
      }

      const input = processEditTreatmentInput(model);

      updateTreatment({ input })
        .then((result) => {
          if (result.errors) {
            model.globalError = 'Something happened.';
            // do something about it...
          }
          router.push({ name: 'admin-treatments' }, () => {});
        });
    };

    return {
      model,
      addNewCycle,
      createNewMedicationPlan,
      getIndexOfID,
      saveTreatment,
      errors,
    };
  },
};
</script>
<template>
  <div class="container">
    <div class="header">
      <h1>{{model.name}}</h1>
    </div>
    <div class="section form">
      <div class="form-input">
        <FormInput
          label="Name"
          v-model="model.name"
          :error="model.showErrors && errors.fields.name.length > 0"
          :errorMessage="errors.fields.name"
        />
      </div>
      <div class="form-input">
        <FormInput
          label="Short Name"
          v-model="model.shortName"
          :error="model.showErrors && errors.fields.shortName.length > 0"
          :errorMessage="errors.fields.shortName"
        />
      </div>
      <div class="form-input">
        <FormInput
          label="Treatment Length (days)"
          v-model="model.length"
          type="number"
          :error="model.showErrors && errors.fields.length.length > 0"
          :errorMessage="errors.fields.length"
        />
      </div>
    </div>
    <div class="section">
      <h2>Pre-Med Options</h2>
      <MedicationPlanFieldset
        v-for="plan in model.premedOptions.filter(item => !item.deleted)"
        :key="plan.id"
        title="PRE-MED OPTION"
        v-model="model.premedOptions[getIndexOfID(model.premedOptions, plan.id)]"
      />
      <span
        class="add-option"
        @click="model.premedOptions.push(createNewMedicationPlan())"
      >
        <svg
          class="plus-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        Add Pre-Med Option
      </span>
    </div>

    <div class="section">
      <h2>Antiviral Options</h2>
      <MedicationPlanFieldset
        v-for="plan in model.antiviralOptions.filter(item => !item.deleted)"
        :key="plan.id"
        title="ANTIVIRAL OPTION"
        v-model="model.antiviralOptions[getIndexOfID(model.antiviralOptions, plan.id)]"
      />
      <span
        class="add-option"
        @click="model.antiviralOptions.push(createNewMedicationPlan())"
      >
        <svg
          class="plus-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        Add Antiviral Option
      </span>
    </div>

    <div class="section">
      <h2>Blood Clot Prevention Options</h2>
      <MedicationPlanFieldset
        v-for="plan in model.bloodClotPreventionOptions.filter(item => !item.deleted)"
        :key="plan.id"
        title="BLOOD CLOT PREVENTION OPTION"
        v-model="model.bloodClotPreventionOptions[
          getIndexOfID(model.bloodClotPreventionOptions, plan.id)
        ]"
      />
      <span
        class="add-option"
        @click="model.bloodClotPreventionOptions.push(createNewMedicationPlan())"
      >
        <svg
          class="plus-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        Add Blood Clot Prevention Option
      </span>
    </div>

    <div class="section">
      <h2>Medications for All Cycles</h2>
      <MedicationPlanFieldset
        v-for="plan in model.medicationsForAllCycles.filter(item => !item.deleted)"
        :key="plan.id"
        title="ALL CYCLE MEDICATION"
        v-model="model.medicationsForAllCycles[
          getIndexOfID(model.medicationsForAllCycles, plan.id)
        ]"
      />
      <span
        class="add-option"
        @click="model.medicationsForAllCycles.push(createNewMedicationPlan())"
      >
        <svg
          class="plus-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        Add Medication for All Cycles
      </span>
    </div>

    <div class="section">
      <h2>Cycles</h2>
      <TreatmentCycleFieldset
        v-for="cycle in model.cycles.filter(item => !item.deleted)"
        :key="cycle.id"
        v-model="model.cycles[getIndexOfID(model.cycles, cycle.id)]"
      />
      <span
        class="add-option"
        @click="addNewCycle"
      >
        <svg
          class="plus-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        Add Cycle
      </span>
    </div>

    <div class="footer">
      <Button @click="saveTreatment">
        Save
      </Button>
    </div>

  </div>
</template>
<style lang="scss" scoped>
  .add-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed $TABLE_BORDER_COLOR;
    color: $textPrimary;
    padding: 1rem;
    cursor: pointer;
    transition: all .25s;

    &:hover {
      border: 2px dashed $BRAND_PRIMARY;
      color: $BRAND_PRIMARY;
      background: rgba($BRAND_PRIMARY, 0.1);
    }

    .plus-icon {
      width: 4rem;
      height: 4rem;
    }
  }

  .container {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 4rem;
  }
  h1 {
    @include header;
    text-align: left;
    padding: 1rem 0;
    font-size: 1.5rem;
    flex: 1;
  }
  h2 {
    @include header;
    text-align: left;
    padding: 1rem 0;
    font-size: 1.25rem;
    flex: 1;
    color: $secondaryHeaderText;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
  }
</style>
